export enum EEditorModes {
  NORMAL = "normal",
}
export type EEditorConfig = {
  currentEditorMode: EEditorModes;
  selectedItemID: string | null;
  backgroundColor: string;
  aspectRatio: number;
  metadata: null | any;
};
