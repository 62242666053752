import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import { initAuth } from "../../../clients/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useSelector } from "react-redux";
import { selectCurrentBusinessMode } from "../../../app/Reducers/AppConfig";
import { EBusinessType } from "../../../app/Reducers/AppConfig/types";
import { onboardingPages } from "../../../pages/OnboardingInitial";

// Define the structure for onboarded status
interface OnboardedStatus {
  pod: boolean;
  dropshipping: boolean;
  branded: boolean;
}

const PrivateRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [onboarded, setOnboarded] = useState<OnboardedStatus | null>(null);

  const currentBusinessType = useSelector(
    selectCurrentBusinessMode,
  ) as EBusinessType | null;
  const auth = initAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        const storedUser = JSON.parse(localStorage.getItem("user") as string);
        if (storedUser && storedUser.onboarded) {
          setOnboarded(storedUser.onboarded);
        }
        setIsAuthenticated(true);
      } else {
        // User is signed out
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (loading || !isAuthenticated) {
      // If still loading or not authenticated, don't proceed with onboarding check
      return;
    }

    if (!currentBusinessType) {
      // If no business type is selected, redirect to onboarding selection
      if (location.pathname !== "/onboarding") {
        navigate("/onboarding");
      }
      return;
    }
    if (!onboarded) return;

    const hasViewedCurrentTutorial = onboarded[currentBusinessType];

    if (!hasViewedCurrentTutorial && location.pathname !== "/packages") {
      if (location.pathname.includes("onboarding")) {
        return;
      }
      // Navigate to the onboarding page specific to the current business type
      navigate(onboardingPages[currentBusinessType]);
    }
    // const viewedPagesRedirect = ["on"]
    if (hasViewedCurrentTutorial && location.pathname.includes("onboarding")) {
      navigate("/");
    }
  }, [
    onboarded,
    currentBusinessType,
    location.pathname,
    isAuthenticated,
    loading,
    navigate,
  ]);

  if (loading) {
    return <div>Loading...</div>; // Or any other loading indicator you prefer
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
